var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { class: { "app-main": true, "not-home": _vm.key !== "/index" } },
    [
      _c(
        "keep-alive",
        [
          _vm.key.indexOf("/portal-login") === -1
            ? _c("router-view", { key: _vm.key })
            : _vm._e(),
        ],
        1
      ),
      _c("portalErrorApp", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.errorApp,
            expression: "errorApp",
          },
        ],
      }),
      _c("keep-alive", [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.errorApp,
              expression: "!errorApp",
            },
          ],
          ref: "animateApp",
          domProps: { innerHTML: _vm._s(_vm.appContent) },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }