var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _c(
        "el-scrollbar",
        {
          staticStyle: { height: "100%", "z-index": "2" },
          attrs: {
            "wrap-class": "main-scroll",
            "view-style": "height: 100%;",
            "wrap-style": "overflow-x: hidden;",
          },
        },
        [
          _vm.device === "mobile" && _vm.sidebar.opened
            ? _c("div", {
                staticClass: "drawer-bg",
                on: { click: _vm.handleClickOutside },
              })
            : _vm._e(),
          _c("sidebar", { staticClass: "sidebar-container" }),
          _c(
            "div",
            { staticClass: "main-container" },
            [_c("navbar"), _c("breadcrumb"), _c("app-main")],
            1
          ),
          _c("drawer", [_c("user-settings")], 1),
          _c("el-backtop", { attrs: { target: ".main-scroll", bottom: 100 } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }