var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      attrs: {
        visible: _vm.currentVisible,
        "close-on-click-modal": !_vm.userInfo.isFirstLogin,
        "show-close": !_vm.userInfo.isFirstLogin,
        width: "600px",
        title: "修改密码",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.currentVisible = $event
        },
        opened: _vm.onOpened,
      },
    },
    [
      _vm.userInfo.isFirstLogin
        ? _c("el-alert", {
            staticClass: "margin-bottom-20",
            attrs: {
              closable: false,
              title:
                "首次登陆必须修改初始密码，密码必须至少包含大小写字母、数字和特殊字符各一位。",
              type: "warning",
              "show-icon": "",
            },
          })
        : _vm.userInfo.isPwdDueSoon
        ? _c("el-alert", {
            staticClass: "margin-bottom-20",
            attrs: {
              closable: false,
              title:
                "密码即将过期请修改密码，密码必须至少包含大小写字母、数字和特殊字符各一位。",
              type: "warning",
              "show-icon": "",
            },
          })
        : _vm.userInfo.global_strongPasswordPolicy
        ? _c("el-alert", {
            staticClass: "margin-bottom-20",
            attrs: {
              closable: false,
              title: "密码必须至少包含大小写字母、数字和特殊字符各一位。",
              type: "warning",
              "show-icon": "",
            },
          })
        : _vm._e(),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "demo-form-inline w-400",
          attrs: { model: _vm.form },
        },
        [
          _vm.isOrginPwd
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": _vm.formLabelWidth,
                    label: "请输入原密码：",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: _vm.passwordType,
                      "auto-complete": "off",
                      readonly: _vm.readonly,
                    },
                    model: {
                      value: _vm.form.originPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "originPassword", $$v)
                      },
                      expression: "form.originPassword",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": _vm.formLabelWidth,
                label: "请输入新密码：",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  type: _vm.passwordType,
                  "auto-complete": "off",
                  readonly: _vm.readonly,
                },
                model: {
                  value: _vm.form.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "password", $$v)
                  },
                  expression: "form.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": _vm.formLabelWidth,
                label: "输入确认密码：",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  type: _vm.passwordType,
                  "auto-complete": "off",
                  readonly: _vm.readonly,
                },
                model: {
                  value: _vm.form.password_repeat,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "password_repeat", $$v)
                  },
                  expression: "form.password_repeat",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          !_vm.userInfo.isFirstLogin
            ? _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.currentVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              )
            : _vm._e(),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.modifypwd } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }